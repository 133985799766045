import React, {useEffect, useState} from 'react';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {List} from "../../components/List";
import styled from "styled-components";
import {StyledFontAwesomeIcon, StyledLink} from "../../components/ListComponents";

const IconsWrapper = styled.div`
    display: flex;
`;

export const WalletList = () => {
    const token = useSelector((store) => store.token);
    const [wallets, setWallets] = useState([]);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {name: 'Właściciel', property: 'user', relationProperty: 'name'},
        {name: 'Stan', property: 'value', type: 'sort'},
        {name: 'Niezapłacone', property: 'notPaid'}
    ];

    useEffect(() => {
        axios.get(`${apiUrl}/wallets`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {wallets, total}}) => {
            setWallets(wallets);
            setTotal(total);
        });
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam])

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.id}</td>
                <td style={{fontWeight: element.user ? "normal" : "bold"}}>{element.user ? element.user.name : 'Nex1'}</td>
                <td>{parseFloat(element.value).toFixed(2)} zł</td>
                <td>{parseFloat(element.notPaid).toFixed(2)} zł</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                <IconsWrapper>
                    <StyledLink to={`/portfele/${element.id}`}>
                        <StyledFontAwesomeIcon size="2x"  icon={faArrowRight} fixedWidth/>
                    </StyledLink>
                </IconsWrapper>
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={wallets}
                actions={actions}
                readonly={false}
                initialTotal={total}
                parentCallback = {callbackFunction}
            ></List>
        </>
    );
};
