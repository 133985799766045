import React, {useEffect, useState} from 'react';
import {InputSearch, Table} from "../../components/Table";
import {
    StyledFontAwesomeIcon,
    StyledFontAwesomeIconActive, StyledFontAwesomeIconBlue,
    StyledFontAwesomeIconGray,
    StyledFontAwesomeIconWarning,
    StyledFontAwesomeIconWhite,
    StyledInlineLink,
    StyledLink,
    StyledPanelContentWrapper
} from "../../components/ListComponents";
import {
    faArrowRight, faCheck,
    faCircle,
    faEye,
    faFileDownload, faMinus, faTimes,
    faTrashAlt,
    faWallet,
    faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import {perPage} from "../../data/variables";
import {downloadPackage} from "../../helpers/file";
import {List} from "../../components/List";

export const VehiclesList = () => {
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [vehicles, setVehicles] = useState([]);
    const [filters, setFilters] = useState(null);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {
            name: 'Sprzedany', property: 'sold_at', type: 'choiceFilter', filterValues: [
                {label: 'Sprzedany', value: 'sold'},
                {label: 'Niesprzedany', value: 'not_sold'}
            ]
        },
        {
            name: 'Magazyn', property: 'isStorage', type: 'choiceFilter', filterValues: [
                {label: 'Tak', value: 'in_storage'},
                {label: 'Nie', value: 'not_in_storage'}
            ]
        },
        {name: 'Magazyn', property: 'isStorage'},
        {name: 'Marka', property: 'mark', type: 'sort'},
        {name: 'Model', property: 'model', type: 'sort'},
        {name: 'Rocznik', property: 'yearbook', type: 'sort'},
        {name: 'Data zakupu', property: 'purchased_at', type: 'sort'},
        {name: 'Koniec umowy', property: 'end_contract_at', type: 'sort'},
        {name: 'Cena zakupu', property: 'gross', type: 'sort'},
        {name: 'Rodzaj finansowania', property: 'financingType', type: 'sort'},
        {name: 'Kwota polisy', property: 'insurancePremium'},
        {name: 'Klient', property: 'client'},
    ];

    const loadData = () => {
        axios.get(`${apiUrl}/vehicle`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam,
                filters: filters
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {vehicles: newVehicles, total}}) => {
            setVehicles(newVehicles);
            setTotal(total);
        });
    }

    useEffect(() => {
        loadData();
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam, filters])

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged, filters) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
        setFilters(filters);
    }

    const renderActiveStatus = (element) => {
        if (element) {
                return (<StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>);
        } else {
            return (<StyledFontAwesomeIconGray size="2x" icon={faMinus} fixedWidth/>);
        }
    }

    const renderStorageStatus = (element) => {
        if (element) {
            return (<StyledFontAwesomeIconBlue size="2x" icon={faWarehouse} fixedWidth/>);
        } else {
            return (<StyledFontAwesomeIconGray size="2x" icon={faWarehouse} fixedWidth/>);
        }
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.vehicle.id}</td>
                <td>{renderActiveStatus(element.vehicle.sold_at)}</td>
                <td>{renderStorageStatus(element.vehicle.isStorage)}</td>
                <td>{element.vehicle.mark ?? '-'}</td>
                <td>{element.vehicle.model ?? '-'}</td>
                <td>{element.vehicle.yearbook ?? '-'}</td>
                <td>{element.vehicle.purchased_at ?? '-'}</td>
                <td>{element.vehicle.end_contract_at ?? '-'}</td>
                <td>{element.vehicle.gross ?? '-'}</td>
                <td>{element.vehicle.financingType ?? '-'}</td>
                <td>{element.policy.insurancePremium ?? '-'}</td>
                <td>{element.vehicle.client ? element.vehicle.client.name : '-'}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                <StyledLink onClick={() => {
                    downloadPackage(token, 'vehicle', element.vehicle.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faFileDownload} fixedWidth/>
                </StyledLink>
                <StyledLink to={`/pojazdy/zobacz/${element.vehicle.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                </StyledLink>
                <StyledLink style={{display: readOnly ? "none" : "block"}}
                            to={`/pojazdy/${element.vehicle.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={vehicles}
                actions={actions}
                readonly={false}
                initialTotal={total}
                parentCallback={callbackFunction}
                search={true}
            ></List>
        </>
    );
};
