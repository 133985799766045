/* eslint-disable react-hooks/exhaustive-deps */
import {Field} from "react-final-form";
import {FileButton, RoundSimpleButton} from "./Form";
import {FieldArray} from "react-final-form-arrays";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faMinus, faPlus, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {isImage, isPdf} from "../helpers/file";
import {ReviewModal} from "./RevierModal";

const FilesContent = styled.div`
    margin-bottom: 20px;
`;
const InputFileWrapper = styled.label`
display: grid;
grid-template-columns: 140px 320px;
justify-content: flex-start;
align-items: center;
margin-bottom: 10px;
font-size: 1.2rem;
position: relative;
input{
position: absolute;
left: 0;
top: 0;
height: 0;
width: 0;
}
`;
const InputFileWrapperWithoutLabel = styled(InputFileWrapper)`
grid-template-columns: 250px;
min-height: 44px;
align-items: center;
  margin-bottom: 0;
  margin-left: 20px;
`;

const StyledA = styled.a`
    color: black;
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
`;

const ButtonsWrapper = styled.div`
    
`;

const FileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const TextInput = styled.input`
    border: none;
    height: 30px;
`

export const Files = ({name, setFilesToForm, label, index, disabled}) => {
    const [files, setFiles] = useState([]);
    const [modal, setModal] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const token = useSelector((store) => store.token);

    useEffect(() => {
        setFilesToForm(files, index)
    }, [files])

    return (
        <FilesContent>
            <FieldArray name={name}>
                {({fields}, index) => (
                    <>
                        <ButtonsWrapper key={index}>
                            <span>{label ?? `Załączniki`}:</span>
                            {!disabled && <RoundSimpleButton
                                type="button"
                                onClick={() => fields.push({})}>
                                <FontAwesomeIcon icon={faPlus} color="white"/>
                            </RoundSimpleButton>}
                            {!disabled && <RoundSimpleButton
                                type="button"
                                onClick={() => {
                                    fields.pop();
                                    const temp = [...files];
                                    temp.pop();
                                    setFiles(temp);
                                }}>
                                <FontAwesomeIcon icon={faMinus} color="white"/>
                            </RoundSimpleButton>}
                        </ButtonsWrapper>
                        {fields.map((name, index) => (
                            <FileWrapper key={index}>
                                <Field
                                    name={`${name}.id`}
                                    render={({input}) => (
                                        <input {...input} id={input.name} hidden/>
                                    )}/>
                                <Field
                                    name={`${name}.name`}
                                    render={({input}) => (
                                        <TextInput {...input} id={input.name} placeholder="" disabled={disabled}/>
                                    )}/>
                                <Field
                                    name={`${name}.file`}
                                    type="file"
                                    render={({input, meta}) => {
                                        let itemFile = files.find(element => (
                                            element.index === index
                                        ));
                                        return <>
                                            <InputFileWrapperWithoutLabel htmlFor={input.name} onChange={e => {
                                                input.onChange(e)
                                                let allFiles = files;
                                                allFiles = allFiles.filter(item => {
                                                    return item.index !== index
                                                })
                                                allFiles.push({
                                                    index: index,
                                                    name: name.replace('.', ''),
                                                    file: e.target.files[0],
                                                    url: null
                                                });
                                                setFiles(allFiles)
                                            }}>
                                                <FileWrapper>
                                                    <input {...input} id={input.name} disabled={disabled}/>
                                                    {(typeof itemFile !== "undefined") ? (
                                                        !disabled && <FileButton>
                                                            {(itemFile && itemFile.file) ? itemFile.file.name : "Wybierz plik"}
                                                        </FileButton>
                                                    ) : (
                                                        !disabled && <FileButton>
                                                            Wybierz plik
                                                        </FileButton>
                                                    )}
                                                    <Field
                                                        name={`${name}.url`}
                                                        render={({input}) => {
                                                            if (input.value && (isPdf(input.value) || isImage(input.value))) {
                                                                return (
                                                                    <StyledA onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setModal(true);
                                                                        setFileUrl(input.value);
                                                                    }}
                                                                             rel="noopener noreferrer"
                                                                             target="_blank">
                                                                        <FontAwesomeIcon icon={faEye} color="gray"
                                                                                         size="2x"/>
                                                                    </StyledA>
                                                                );
                                                            }
                                                        }}/>
                                                    <Field
                                                        name={`${name}.url`}
                                                        render={({input}) => {
                                                            if (input.value) {
                                                                return (
                                                                    <StyledA href={input.value}
                                                                             rel="noopener noreferrer"
                                                                             target="_blank">
                                                                        <FontAwesomeIcon icon={faSave} color="gray"
                                                                                         size="2x"/>
                                                                    </StyledA>
                                                                );
                                                            }
                                                        }}/>
                                                    <Field
                                                        name={`${name}.url`}
                                                        render={({input}) => {
                                                            if (input.value && !disabled) {
                                                                return (
                                                                    <StyledA className="file-delete"
                                                                             rel="noopener noreferrer"
                                                                             onClick={(e) => {
                                                                                 e.preventDefault();
                                                                                 let allFiles = files;
                                                                                 allFiles = allFiles.filter(item => {
                                                                                     return item.index !== index
                                                                                 });

                                                                                 axios.delete(`${input.value}`, {
                                                                                     headers: {
                                                                                         Authorization: `Bearer ${token}`,
                                                                                     }
                                                                                 }).then(() => {
                                                                                     setFiles(allFiles);
                                                                                     fields.remove(index);
                                                                                     toast.success("Plik został usunięty.");
                                                                                 }).catch(() => {
                                                                                     toast.error("Wystąpił błąd.");
                                                                                 });
                                                                             }}
                                                                             target="_blank">
                                                                        <FontAwesomeIcon icon={faTrashAlt} color="gray"
                                                                                         size="2x"/>
                                                                    </StyledA>
                                                                );
                                                            }
                                                        }}/>
                                                </FileWrapper>
                                            </InputFileWrapperWithoutLabel>
                                        </>;
                                    }}
                                />
                            </FileWrapper>
                        ))}
                    </>
                )}
            </FieldArray>

            <ReviewModal
                modal={modal}
                fileUrl={fileUrl}
                onClose={() => {
                    setModal(false);
                    setFileUrl(null);
                }}
            ></ReviewModal>
        </FilesContent>
    );
}
