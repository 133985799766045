import React, {useState} from "react";
import {Field} from "react-final-form";
import {FileButton, InputFileWrapper} from "./Form";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faSave} from "@fortawesome/free-solid-svg-icons";
import {isImage, isPdf} from "../helpers/file";
import {ReviewModal} from "./RevierModal";

const StyledA = styled.a`
  color: black;
  text-decoration: none;
  margin-left: 10px;
`;

export const File = ({name, label, initialValue, onFileSelect}) => {
    const [file, setFile] = useState(null);
    const [modal, setModal] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);

    const handleFileInput = (e) => {
        setFile(e.target.files[0]);
        onFileSelect(e.target.files[0]);
    };

    return (
        <>
            <Field
                name={`${name}`}
                type="file"
                render={({input}) => (
                    <>
                        <InputFileWrapper
                            htmlFor={input.name}
                            onChange={handleFileInput}
                        >
                            <input {...input} id={input.name}/>
                            {label && <span>{label}</span>}
                            <FileButton>{file ? file.name : "Wybierz plik"}</FileButton>
                            <Field
                                name={`${name}.url`}
                                render={({input}) => {

                                    if (initialValue && (isPdf(initialValue) || isImage(initialValue))) {
                                        return (
                                            <StyledA onClick={(e) => {
                                                e.preventDefault();
                                                setModal(true);
                                                setFileUrl(initialValue);
                                            }}
                                                     rel="noopener noreferrer"
                                                     target="_blank">
                                                <FontAwesomeIcon icon={faEye} color="gray"
                                                                 size="2x"/>
                                            </StyledA>
                                        );
                                    }
                                }}/>
                            <Field
                                name={`${name}.url`}
                                render={({input}) => {
                                    if (initialValue) {
                                        return (
                                            <StyledA href={initialValue}
                                                     rel="noopener noreferrer"
                                                     target="_blank">
                                                <FontAwesomeIcon icon={faSave} color="gray"
                                                                 size="2x"/>
                                            </StyledA>
                                        );
                                    }
                                }}/>
                        </InputFileWrapper>
                    </>
                )}>
            </Field>

            <ReviewModal
                modal={modal}
                fileUrl={fileUrl}
                onClose={() => {
                    setModal(false);
                    setFileUrl(null);
                }}
            ></ReviewModal>
        </>
    )
};