import React, {useEffect, useState} from 'react';
import {
    StyledFontAwesomeIcon,
    StyledFontAwesomeIconActive,
    StyledFontAwesomeIconWarning, StyledFontAwesomeIconWhite,
    StyledLink
} from "../../components/ListComponents";
import {faArrowRight, faCircle, faCopy, faEye} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import {List} from "../../components/List";

const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
    cursor: pointer;
`;

export const ClientsInsurancePolicy = ({clientId}) => {
    const token = useSelector((store) => store.token);
    const [policies, setPolicies] = useState([]);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [redirect, setRedirect] = useState(null);

    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");

    const getType = (type) => {
        return type === 'policy' ? 'Polisa' : 'GAP';
    }

    const renderActiveStatus = (param) => {
        switch (param) {
            case 'active':
                return (<StyledFontAwesomeIconActive size="2x" icon={faCircle} fixedWidth/>);
            case 'inactive':
                return (<StyledFontAwesomeIcon size="2x" icon={faCircle} fixedWidth/>);
            case 'warning':
                return (<StyledFontAwesomeIconWarning size="2x" icon={faCircle} fixedWidth/>);
            default:
                return (<StyledFontAwesomeIconWhite size="2x" icon={faCircle} fixedWidth/>);
        }
    }

    const loadData = () => {
        axios.get(`${apiUrl}/client/${clientId}/policy`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {policies: newPolicies, total}}) => {
            setPolicies(newPolicies);
            setTotal(total);
        });
    }

    useEffect(() => {
        loadData();
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam]);

    useEffect(() => {
        if (duplicateModal === false) {
            loadData();
        }
    }, [duplicateModal])

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {name: 'Aktywna', property: ''},
        {name: 'TU', property: 'tu'},
        {name: 'Nr polisy', property: 'number'},
        {name: 'Typ', property: 'type'},
        {name: 'Marka', property: 'vehicleMark'},
        {name: 'Model', property: 'vehicleModel'},
        {name: 'Nr rejestracyjny', property: 'vehicleRegistrationNumber'},
        {name: 'Data początku', property: 'startedAt'},
        {name: 'Data końca', property: 'endedAt'},
        {name: 'Wystawca polisy', property: 'tuIssuer'},
        {name: 'Klient', property: 'clientName'},
        {name: 'Doprowadzający', property: 'clientLeader'},
    ];

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.id}</td>
                <td>{renderActiveStatus(element.active)}</td>
                <td>{element.tu ?? '-'}</td>
                <td>{element.number ?? '-'}</td>
                <td style={{paddingLeft: 10, paddingRight: 10}}>{getType(element.type)}</td>
                <td>{element.vehicleMark ?? '-'}</td>
                <td>{element.vehicleModel ?? '-'}</td>
                <td>{element.vehicleRegistrationNumber ?? '-'}</td>
                <td>{element.startedAt ?? '-'}</td>
                <td>{element.endedAt ?? '-'}</td>
                <td>{element.tuIssuer ?? '-'}</td>
                <td>{element.clientName ?? '-'}</td>
                <td>{element.clientLeader ?? '-'}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                {!readOnly && element.type === 'policy' && (
                    <StyledButton onClick={() => {
                        setDuplicateModal(element.to_do_id);
                    }}>
                        <StyledFontAwesomeIcon size="2x" icon={faCopy} fixedWidth/>
                    </StyledButton>
                )}
                {!readOnly && <StyledLink to={`/polisy/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>}
                {readOnly && <StyledLink to={`/polisy/zobacz/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                </StyledLink>}
            </>
        )
    }

    return (
        <>
            {redirect && <Redirect to={redirect}/>}

            <List
                tableColumns={tableColumns}
                cells={cells}
                data={policies}
                actions={actions}
                readonly={readOnly}
                initialTotal={total}
                parentCallback={callbackFunction}
                disableMargin={true}
            ></List>

            {duplicateModal && <Modal closeModal={() => {
                setDuplicateModal(false)
            }}>
                <P>Czy na pewno chcesz wznowić polisę?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.post(`${apiUrl}/toDo/${duplicateModal}/duplicate`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then((response) => {
                            toast.success("Zadanie zostało zduplikowane.");
                            setRedirect(`/zadania/${response.data.toDo.id}`);
                        }).catch((e) => {
                            toast.error("Nie możesz zduplikować zadania.");
                        }).finally(() => {
                            setDuplicateModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setDuplicateModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
