import React, {useEffect, useState} from 'react';
import PanelContentWrapper from "../../components/PanelContentWrapper";
import axios from "axios";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {StyledLink} from "../../components/ListComponents";
import {toast} from "react-toastify";
import {Field, Form} from "react-final-form";
import {Button} from "../../components/Buttons";
import {Select} from "../../components/Form";
import {Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faSave} from "@fortawesome/free-solid-svg-icons";
import {ReviewModal} from "../../components/RevierModal";
import {Files} from "../../components/Files";
import arrayMutators from "final-form-arrays";
import {createFormData} from "../../helpers/form";

const TaskInfo = styled.div`
  font-size: 14px;
  display: flex;
  margin: 10px 0;
  justify-content: flex-start;

  span:first-child {
    font-weight: bold;
  }
`;

const StyledLinkCustom = styled(StyledLink)`
  margin: 0;
`;

const StyledForm = styled.form`
  flex-shrink: 0;
`;

const StyledTextarea = styled.textarea`
  resize: none;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
`;

const ButtonWrapper = styled.div`
    margin-left: 10px;
`;

const CommentsWrapper = styled.div`
  background: white;
  margin: 20px 0;
  padding: 10px;
`;
const Comment = styled.div`
  border-bottom: 1px solid gray;
  padding-bottom: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

const CommentContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CommentDate = styled.span`
  min-width: 110px;
  margin-left: 10px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 10px 0;
`;

const StyledSelect = styled(Select)`
    margin-right: 10px;
`;

const StyledA = styled.a`
    color: black;
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
`;

const FilesList = styled.div`
    display: flex;
    font-size: 1.2rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 10px;
`;

const CommentFilesList = styled(FilesList)`
    align-self: flex-end;
    font-size: 1rem;
`;

const FileWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    flex-direction: row;
`;

const FileLabel = styled.div`
    padding-top: 5px;
`;

export const Task = ({match, type}) => {
    const token = useSelector((store) => store.token);
    const [id] = useState(match.params.id);
    const [task, setTask] = useState(null);
    const [comments, setComments] = useState([]);
    const [users, setUsers] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [modal, setModal] = useState(false);
    const [globalFiles, setGlobalFiles] = useState([]);

    useEffect(() => {
        if (id) {
            let url = apiUrl;
            if (type === 'toDo') {
                url += `/tasks/show/${id}`;
            } else if (type === 'personal') {
                url += `/personal/tasks/show/${id}`;
            }

            axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({data: {task, comments: newComments, users}}) => {
                setTask(task);
                setComments(newComments);
                setUsers(users);
            })
        }
    }, [id, type, token]);

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (!globalFiles) {
            return;
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }


    return (
        <PanelContentWrapper>
            {redirect && <Redirect to={redirect}/>}
            <h1>Zadanie</h1>
            {task?.client_id && (
                <TaskInfo>
                    <span>Klient:</span>
                    <StyledLinkCustom to={`/klienci/${task.client_id}`}>
                        <span>{task?.clientName}</span>
                    </StyledLinkCustom>
                </TaskInfo>
            )}
            {task?.to_do_id && (
                <TaskInfo>
                    <span>Przypisane do zadania:</span>
                    <StyledLinkCustom to={`/zadania/${task.to_do_id}`}>
                        <span>{task.to_do_id}</span>
                    </StyledLinkCustom>
                </TaskInfo>
            )}
            <TaskInfo>
                <span>Dla:</span>
                <span>{task?.userName}</span>
            </TaskInfo>
            <div>
                <Form
                    onSubmit={(values) => {
                        let url = apiUrl;
                        if (type === 'toDo') {
                            url += `/tasks/${id}/changeUser`;
                        } else if (type === 'personal') {
                            url += `/personal/tasks/${id}/changeUser`;
                        }

                        axios.put(url, values, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            setRedirect('/dashboard');
                            toast.success('Zmieniono użytkownika');
                        }).catch((e) => {
                            if (e.response?.status === 422) {
                                toast.error("Wiadomość musi mieć treść");
                            } else {
                                toast.error('Coś poszło nie tak.');
                            }
                        })
                    }}
                    initialValues={{
                        user_id: task?.user_id
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Field
                                name="user_id"
                                render={({input, meta}) => (
                                    <StyledSelect {...input} id={input.name}>
                                        <option value="">Użytkownik</option>
                                        {users.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </StyledSelect>
                                )}/>
                            <Button type="submit" small smallText>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </div>
            <TaskInfo>
                <span>Od:</span>
                <span>{task?.masterName}</span>
            </TaskInfo>
            <TaskInfo>
                <span>Opis:</span>
                <span>{task?.description}</span>
            </TaskInfo>
            <TaskInfo>
                <span>Data zadania:</span>
                <span>{task?.date}</span>
            </TaskInfo>
            <TaskInfo>
                <span>Utworzenie zadania:</span>
                <span>{task?.created_at}</span>
            </TaskInfo>
            <TaskInfo>
                <span>Status zadania:</span>
                <span>{task?.statusName}</span>
            </TaskInfo>
            <TaskInfo>
                <span>Zamiana statusu zadania:</span>
                <span>{task?.change_status_at}</span>
            </TaskInfo>
            {task?.files.length > 0 && (
                <>
                    <TaskInfo>
                        <span>Załącznik:</span>
                        <FilesList>
                            {task?.files.map((element) => (
                                <FileWrapper>
                                    <FileLabel>{element.name}</FileLabel>
                                    <StyledA onClick={(e) => {
                                        e.preventDefault();
                                        setModal(true);
                                        setFileUrl(element.url);
                                    }}
                                             rel="noopener noreferrer"
                                             target="_blank">
                                        <FontAwesomeIcon icon={faEye} color="gray"
                                                         size="2x"/>
                                    </StyledA>
                                    <StyledA href={element.url}
                                             rel="noopener noreferrer"
                                             target="_blank">
                                        <FontAwesomeIcon icon={faSave} color="gray"
                                                         size="2x"/>
                                    </StyledA>
                                </FileWrapper>
                            ))}
                        </FilesList>
                    </TaskInfo>
                </>
            )}
            {comments.length > 0 && (
                <CommentsWrapper>
                    {comments.map((comment) => (
                        <Comment>
                            <div>
                                <span>Od: {comment.userName}</span>
                            </div>
                            <CommentContent>
                                <span>{comment.message}</span>
                                <CommentDate>{comment.created_at}</CommentDate>
                            </CommentContent>
                            {comment.files.length > 0 && (
                                <>
                                        <CommentFilesList>
                                            {comment.files.map((element) => (
                                                <FileWrapper>
                                                    <FileLabel>{element.name}</FileLabel>
                                                    <StyledA onClick={(e) => {
                                                        e.preventDefault();
                                                        setModal(true);
                                                        setFileUrl(element.url);
                                                    }}
                                                             rel="noopener noreferrer"
                                                             target="_blank">
                                                        <FontAwesomeIcon icon={faEye} color="gray"
                                                                         size="2x"/>
                                                    </StyledA>
                                                    <StyledA href={element.url}
                                                             rel="noopener noreferrer"
                                                             target="_blank">
                                                        <FontAwesomeIcon icon={faSave} color="gray"
                                                                         size="2x"/>
                                                    </StyledA>
                                                </FileWrapper>
                                            ))}
                                        </CommentFilesList>
                                </>
                            )}
                        </Comment>
                    ))}
                </CommentsWrapper>
            )}
            <div>
                <Form
                    onSubmit={(values, form) => {
                        let url = apiUrl;
                        if (type === 'toDo') {
                            url += `/comments/${id}`;
                        } else if (type === 'personal') {
                            url += `/personal/comments/${id}`;
                        }
                        const formData = new FormData();
                        Object.keys(values).forEach((key) => {
                            formData.append(key, values[key] ?? '')
                        })
                        createFormData(formData, 'files', values.files);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({files}) => {
                                files.forEach((file) => {
                                    formData.append(`files[${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(url, formData, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {comments: newComments}}) => {
                            setComments(newComments);
                            setGlobalFiles([]);

                            form.reset();
                            toast.success('Dodano komentarz');
                        }).catch((e) => {
                            if (e.response?.status === 422) {
                                toast.error("Wiadomość musi mieć treść");
                            } else {
                                toast.error('Coś poszło nie tak.');
                            }
                        })
                    }}
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        ...arrayMutators
                    }}
                    render={({handleSubmit}) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <Files name={`files`} index={0} setFilesToForm={setFilesToForm} label={`Załączniki`}/>
                            <Field
                                name='message'
                                render={({input, meta}) => (
                                    <StyledTextarea name="" id="" cols="30" rows="4" {...input}/>
                                )}/>
                            <ButtonsWrapper>
                                <ButtonWrapper>
                                    <Button type="submit" small smallText>Zapisz</Button>
                                </ButtonWrapper>
                            </ButtonsWrapper>
                        </StyledForm>
                    )}
                />
            </div>

            <ReviewModal
                modal={modal}
                fileUrl={fileUrl}
                onClose={() => {
                    setModal(false);
                    setFileUrl(null);
                }}
            ></ReviewModal>
        </PanelContentWrapper>
    );
}
