import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
    position: relative;
    border-bottom: 1px solid #827E7E;
    font-size: 1.6rem;
    padding-top: 20px;
    margin-bottom: 20px;
    width: 100%;

    input {
        border: none;
        width: 100%;
        font-size: 1.6rem;
        padding-bottom: 10px;
        font-weight: 300;
        background: transparent;
    }

    select {
        width: 100%;
        background: transparent;
        border: none;
        margin-bottom: 10px;
        font-size: 1.6rem;
        font-weight: 300;
    }

    label {
        position: absolute;
        transform: scale(1);
        left: 0;
        transition: transform .2s ease-in-out;
        font-size: 1.6rem;
        font-weight: 300;
    }

    input:focus {
        outline: none;
    }

    input:not([value=""]) ~ label, input:focus ~ label {
        transform: scale(0.7) translateY(-150%);
    }
`;

const StyledDivError = styled.div`
    color: red;
`;

export const InputFloating = ({id, name, className, label, error, submitError, touched, ...props}) => {
    return (
        <>
            <InputWrapper className={className}>
                <input name={name} id={id || name} {...props} />
                <label htmlFor={id || name}>{label || name}</label>
            </InputWrapper>
            {(error || submitError) && touched && (
                <StyledDivError>{error || submitError}</StyledDivError>
            )}
        </>
    )
}


export const SelectFloating = ({id, name, className, label, children, error, touched, ...props}) => {
    return (
        <InputWrapper className={className}>
            <select name={name} id={id || name} {...props} >
                {children}
            </select>
            {error && touched && <StyledDivError>{error}</StyledDivError>}
        </InputWrapper>
    )
}


export const Input = styled.input`
    font-size: 1.2rem;
    border: none;
    padding: 5px 8px;
`;

export const Label = styled.label`
    font-size: 1.2rem;
`;

export const VariantLabel = styled.label`
    display: flex;
    justify-content: center;
`;

export const Select = styled.select`
    font-size: 1.2rem;
    border: none;
    padding: 5px 8px;
`;

const StyledSwitch = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    border-radius: 20px;
    margin-bottom: 10px;
    width: 50px;
    background-color: ${({checked}) => checked ? '#D0E9D3' : '#C4C4C4'};
    transition: background-color .3s ease-in-out;
    position: relative;

    input {
        height: 0;
        width: 0;
        position: absolute;
        left: 0;
        top: 0;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 25px;
        height: 25px;
        background-color: ${({checked, theme: {colors: {red}}}) => checked ? '#20A82E' : red};
        border-radius: 25px;
        transform: translateX(${({checked}) => checked ? '100%' : "0"});
        transition: transform .3s ease-in-out, background-color .3s ease-in-out;
    }
`;

export const Switch = ({className, checked, ...props}) => {
    return (
        <StyledSwitch className={className} checked={checked}>
            <input type="checkbox" {...props} checked={checked}/>
        </StyledSwitch>
    )
};

export const FileButton = styled.span`
    display: flex;
    align-items: center;
    border-radius: 10000px;
    padding: 8px 35px;
    background-color: ${({theme: {colors: {gray}}}) => gray};;
    color: ${({theme: {colors: {white}}}) => white};
    justify-self: flex-start;
`;

export const TopFormWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;


    div.links {
        min-width: 15%;
        display: flex;
        flex-direction: row;

        a {
            margin-left: 5px;
            margin-top: 2px;
            text-decoration: none;
            width: 50%;
            color: black;
            cursor: pointer;
            font-size: 14px;
        }
    }
`;

export const RoundButton = styled.button`
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: ${({theme: {colors: {gray}}}) => gray};
    position: absolute;
    left: 0;
    top: -20px;
`;

export const RoundSimpleButton = styled.button`
    margin-left: 5px;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: ${({theme: {colors: {gray}}}) => gray};
`;

export const ListRemoveButton = styled.button`
    border: none;
    border-radius: 5px;
    width: 50px;
    height: 15px;
    background-color: ${({theme: {colors: {red}}}) => red};
    color: ${({theme: {colors: {white}}}) => white};
    cursor: pointer;
    font-size: 1.1rem;
`;

export const ListAddButton = styled.button`
    border: none;
    border-radius: 5px;
    width: 50px;
    height: 13px;
    background-color: ${({theme: {colors: {green}}}) => green};
    color: ${({theme: {colors: {white}}}) => white};
    cursor: pointer;
    font-size: 1.1rem;
`;

export const InputFileWrapper = styled.label`
    display: grid;
    grid-template-columns: 160px auto auto auto;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
    position: relative;

    input {
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
        width: 0;
    }
`;
