import React, {useEffect, useState} from 'react';
import {StyledFontAwesomeIcon} from "../../components/ListComponents";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";
import {List} from "../../components/List";

const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;

export const ClientsTasks = ({clientId}) => {
    const token = useSelector((store) => store.token);
    const [tasks, setTasks] = useState([]);
    const [modal, setModal] = useState(null);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");

    const tableColumns = [
        {name: 'Klient', property: 'name', type: 'sort'},
        {name: 'Nazwa zadania', property: 'email', type: 'sort'},
        {name: 'Data zadania', property: 'wallet', relationProperty: 'value'},
        {name: 'Data utworzenia', property: 'role'},
        {name: 'Data zakończenia', property: 'role'},
    ];

    const loadData = () => {
        axios.get(`${apiUrl}/personal/tasks/${clientId}`, {
            params: {
                page
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {tasks: newTasks, total}}) => {
            setTasks(newTasks);
            setTotal(total);
        });
    }

    useEffect(() => {
        loadData();
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam])

    useEffect(() => {
        if (modal === false) {
            loadData();
        }
    }, [modal])

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.clientName ?? '-'}</td>
                <td>{element.description}</td>
                <td>{element.date}</td>
                <td>{element.created_at}</td>
                <td>{element.change_status_at ?? '-'}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                {element.status === 0 ? (
                    <StyledButton onClick={() => {
                        setModal(element.id);
                    }}>
                        <StyledFontAwesomeIcon size="2x" icon={faTimes} fixedWidth/>
                    </StyledButton>
                ):(
                    <StyledButton>
                        <StyledFontAwesomeIcon size="2x" icon={faCheck} fixedWidth/>
                    </StyledButton>
                )}
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={tasks}
                actions={actions}
                readonly={false}
                initialTotal={total}
                parentCallback={callbackFunction}
                disableMargin={true}
            ></List>

            {modal && <Modal closeModal={() => {
                setModal(null)
            }}>
                <P>Czy na pewno chcesz zakończyć zadanie?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.put(`${apiUrl}/personal/tasks/${modal}`,{}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Zadanie zostało zakończone.");
                        }).catch((e)=>{
                            toast.error("Nie możesz zatwierdzić zadania.");
                        }).finally(()=>{
                            setModal(null);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setModal(null)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
