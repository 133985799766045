import React, {useEffect, useState} from 'react';
import {InputSearch, Table} from "../../components/Table";
import {faArrowRight, faCopy, faEye, faFileDownload, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {StyledFontAwesomeIcon, StyledLink, StyledPanelContentWrapper} from "../../components/ListComponents";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Pagination} from "@material-ui/lab";
import styled from "styled-components";
import {Modal} from "../../components/Modal";
import {Button} from "../../components/Buttons";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {toast} from "react-toastify";
import {perPage} from "../../data/variables";
import {downloadPackage} from "../../helpers/file";
import {List} from "../../components/List";

const IconsWrapper = styled.div`
    display: flex;
`;
const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;


export const InsuranceCompaniesList = () => {
    const token = useSelector((store) => store.token);
    const fullAccess = ['Współwłaściciel'].includes(useSelector((store) => store.roleName));
    const readOnly = ['Administrator', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [companies, setCompanies] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");

    const loadData = () => {
        axios.get(`${apiUrl}/companies`, {
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(({data: {companies: newCompanies, total}}) => {
            setCompanies(newCompanies);
            setTotal(total);
        });
    }

    useEffect(() => {
        loadData();
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam])

    useEffect(() => {
        if (openModal === false) {
            loadData();
        }
    }, [openModal])

    const tableColumns = [
        {name: 'ID', property: 'id'},
        {name: 'Nazwa', property: 'name'},
        {name: 'Prowizja', property: 'commission'}
    ];

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.id}</td>
                <td>{element.name}</td>
                <td>{element.commission ?? '-'}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                <IconsWrapper>
                    {readOnly && <StyledLink to={`/firmy/zobacz/${element.id}`}>
                        <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                    </StyledLink>}

                    {fullAccess && <StyledButton onClick={() => {
                        setOpenModal(element.id);
                    }}>
                        <StyledFontAwesomeIcon size="2x" icon={faTrashAlt} fixedWidth/>
                    </StyledButton>}

                    {!readOnly && <StyledLink style={{display: readOnly ? "none" : "block"}} to={`/firmy/${element.id}`}>
                        <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                    </StyledLink>}
                </IconsWrapper>
            </>
        )
    }

    return (
        <>
            <List
                tableColumns={tableColumns}
                cells={cells}
                data={companies}
                actions={actions}
                readonly={readOnly}
                initialTotal={total}
                parentCallback={callbackFunction}
                search={true}
            ></List>

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć firmę?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/companies/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Firma została usunięta.");
                        }).catch((e) => {
                            toast.error(e.message);
                        }).finally(() => {
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
