import React, {useEffect, useState} from 'react';
import {
    StyledFontAwesomeIcon,
    StyledFontAwesomeIconActive, StyledFontAwesomeIconWarning, StyledFontAwesomeIconWhite,
    StyledLink,
} from "../../components/ListComponents";
import {
    faArrowRight,
    faCircle,
    faCopy,
    faEye,
    faFileDownload,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import axios from "axios";
import {apiUrl} from "../api";
import {Modal} from "../../components/Modal";
import {ModalButtonWrapper, P} from "../../components/Modals";
import {Button} from "../../components/Buttons";
import {toast} from "react-toastify";
import styled from "styled-components";
import {Redirect} from "react-router-dom";
import {downloadPackage} from "../../helpers/file";
import {List} from "../../components/List";

const StyledButton = styled.button`
    border: none;
    background: transparent;
    color: ${({theme: {colors: {red}}}) => red};;
    font-weight: 900;
    font-size: 1.6rem;
    top: 5px;
    right: 5px;
`;

export const InsurancePoliciesList = () => {
    const [policies, setPolicies] = useState([]);
    const token = useSelector((store) => store.token);
    const readOnly = ['Partner', 'Księgowy'].includes(useSelector((store) => store.roleName));
    const [redirect, setRedirect] = useState(null);
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState("");
    const [filters, setFilters] = useState(null);
    const [sortDirection, setSortDirection] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchParam, setSearchParam] = useState("");
    const [duplicateModal, setDuplicateModal] = useState(false);
    const [policyId, setPolicyId] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const getType = (type) => {
        return type === 'policy' ? 'Polisa' : 'GAP';
    }

    const renderActiveStatus = (param) => {
        switch (param) {
            case 'active':
                return (<StyledFontAwesomeIconActive size="2x" icon={faCircle} fixedWidth/>);
            case 'inactive':
                return (<StyledFontAwesomeIcon size="2x" icon={faCircle} fixedWidth/>);
            case 'warning':
                return (<StyledFontAwesomeIconWarning size="2x" icon={faCircle} fixedWidth/>);
            default:
                return (<StyledFontAwesomeIconWhite size="2x" icon={faCircle} fixedWidth/>);
        }
    }

    const loadData = () => {
        axios.get(`${apiUrl}/policy`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                page: page,
                rowsPerPage: rowsPerPage,
                sort: sort,
                sortDirection: sortDirection,
                search: searchParam,
                filters: filters
            }
        }).then(({data: {policies: newPolicies, total}}) => {
            setPolicies(newPolicies);
            setTotal(total);
        });
    }

    useEffect(() => {
        loadData();
    }, [token, page, rowsPerPage, sort, sortDirection, searchParam, filters]);

    useEffect(() => {
        if (openModal === false || duplicateModal === false) {
            loadData();
        }
    }, [openModal, duplicateModal])


    const tableColumns = [
        {name: 'ID', property: 'id'},
        {
            name: 'Aktywna', property: 'active', type: 'choiceFilter', filterValues: [
                {label: 'Aktywne', value: 'active'},
                {label: 'Niekatywne', value: 'inactive'},
                {label: 'Warning', value: 'warning'}
            ]
        },
        {name: 'TU', property: 'tu', type: 'sort'},
        {name: 'Typ', property: 'type', type: 'sort'},
        {name: 'Marka', property: 'vehicleMark'},
        {name: 'Model', property: 'vehicleModel'},
        {name: 'Składka', property: 'insurancePremium', type: 'sort'},
        {name: 'Data początku', property: 'startedAt', type: 'sort'},
        {name: 'Data końca', property: 'endedAt', type: 'sort'},
        {name: 'Wystawca polisy', property: 'tuIssuer', type: 'sort'},
        {name: 'Klient', property: 'clientName'},
        {name: 'Doprowadzający', property: 'clientLeader'},
    ];

    const callbackFunction = (pageChanged, rowsPerPageChanged, sortChanged, sortDirectionChanged, searchParamChanged, filters) => {
        setPage(pageChanged + 1);
        setRowsPerPage(rowsPerPageChanged);
        setSort(sortChanged);
        setSortDirection(sortDirectionChanged);
        setSearchParam(searchParamChanged);
        setFilters(filters);
    }

    const cells = (element) => {
        return (
            <>
                <td>{element.id}</td>
                <td>{renderActiveStatus(element.active)}</td>
                <td>{element.tu ?? '-'}</td>
                <td style={{paddingLeft: 10, paddingRight: 10}}>{getType(element.type)}</td>
                <td>{element.vehicleMark ?? '-'}</td>
                <td>{element.vehicleModel ?? '-'}</td>
                <td>{element.insurancePremium ?? '-'}</td>
                <td>{element.startedAt ?? '-'}</td>
                <td>{element.endedAt ?? '-'}</td>
                <td>{element.tuIssuer ?? '-'}</td>
                <td>{element.clientName ?? '-'}</td>
                <td>{element.clientLeader ?? '-'}</td>
            </>
        );
    }

    const actions = (element) => {
        return (
            <>
                {!readOnly && element.to_do_id !== null && element.type === 'policy' && (
                    <StyledButton onClick={() => {
                        setDuplicateModal(element.to_do_id);
                        setPolicyId(element.id);
                    }}>
                        <StyledFontAwesomeIcon size="2x" icon={faCopy} fixedWidth/>
                    </StyledButton>
                )}
                {!readOnly && <StyledButton onClick={() => {
                    setOpenModal(element.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faTrashAlt} fixedWidth/>
                </StyledButton>}

                <StyledLink onClick={() => {
                    downloadPackage(token, 'policy', element.id);
                }}>
                    <StyledFontAwesomeIcon size="2x" icon={faFileDownload} fixedWidth/>
                </StyledLink>
                {readOnly && <StyledLink to={`/polisy/zobacz/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faEye} fixedWidth/>
                </StyledLink>}
                {!readOnly && <StyledLink to={`/polisy/${element.id}`}>
                    <StyledFontAwesomeIcon size="2x" icon={faArrowRight} fixedWidth/>
                </StyledLink>}
            </>
        )
    }

    return (
        <>
            {redirect && <Redirect to={redirect}/>}

            <List
                tableColumns={tableColumns}
                cells={cells}
                data={policies}
                actions={actions}
                readonly={readOnly}
                initialTotal={total}
                parentCallback={callbackFunction}
                search={true}
            ></List>

            {duplicateModal && policyId && <Modal closeModal={() => {
                setDuplicateModal(false)
                setPolicyId(false)
            }}>
                <P>Czy na pewno chcesz wznowić polisę?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.post(`${apiUrl}/toDo/${duplicateModal}/duplicate/${policyId}`, {}, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then((response) => {
                            toast.success("Zadanie zostało zduplikowane.");
                            setRedirect(`/zadania/${response.data.toDo.id}`);
                        }).catch((e) => {
                            toast.error("Nie możesz zduplikować zadania.");
                        }).finally(() => {
                            setDuplicateModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setDuplicateModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}

            {openModal && <Modal closeModal={() => {
                setOpenModal(false)
            }}>
                <P>Czy na pewno chcesz usunąć polisę?</P>

                <ModalButtonWrapper>
                    <Button onClick={() => {
                        axios.delete(`${apiUrl}/policy/${openModal}`, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(() => {
                            toast.success("Polisa została usunięta.");
                        }).catch((e) => {
                            if (e.response?.status === 403) {
                                toast.error("Brak pozwolenia");
                            } else {
                                toast.error(e.message);
                            }
                        }).finally(() => {
                            setOpenModal(false);
                        });
                    }}>Tak</Button>
                    <Button inactive onClick={() => setOpenModal(false)}>NIE</Button>
                </ModalButtonWrapper>
            </Modal>}
        </>
    );
};
