export default {
    colors: {
        red: "#E73C3D",
        white: "#fff",
        black: "#000",
        lightGray: "#F0F0F0",
        gray: "#827E7E",
        darkGray: "#192B38",
        darkerGray: "#232323",
        green: "#008000",
        orange: "#FFA500",
        blue: "#6395ee"
    }
}
