import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Field, Form} from "react-final-form";
import styled from "styled-components";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from 'react-final-form-arrays'
import axios from "axios";
import {Input, Label, RoundSimpleButton, Select, Switch, TopFormWrapper, VariantLabel} from "../../components/Form";
import {Button} from "../../components/Buttons";
import {apiUrl} from "../api";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {maxValue} from "../../validators/base";
import {downloadFile} from "../../helpers/file";
import {Status} from "../../components/Status";
import {Files} from "../../components/Files";
import {createFormData} from "../../helpers/form";
import {Comment} from "../Comment/Comment";

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  position: relative;

  & > div {
    margin-right: 20px;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 140px 320px;
  align-items: center;
  margin-bottom: 10px;
`;

const InputWrapperWithMargin = styled(InputWrapper)`
  grid-template-columns: 105px 320px;
  margin-left: 35px;
`;

const InputWrapperWithoutLabel = styled(InputWrapper)`
  grid-template-columns: 200px;
  align-items: center;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin-right: 15px;
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: -5px;
`;

const StyledDivError = styled.div`
  color: red;
`;

const FieldSet = styled.fieldset`
  border: none;
`;

export const FinanceTab = ({toDoId, archived}) => {
    const [isEnabled, setIsEnabled] = useState(false);
    const [isRenew, setIsRenew] = useState(false);
    const token = useSelector((store) => store.token);
    const [clientName, setClientName] = useState(null);
    const [slack, setSlack] = useState(null);
    const [defaultNet, setDefaultNet] = useState(null);
    const [finances, setFinances] = useState([]);
    const [status, setStatus] = useState({});
    const [statuses, setStatuses] = useState([]);
    const [globalFiles, setGlobalFiles] = useState([]);

    const validate = (formsData) => {
        const errors = {finances: []}
        formsData.finances.forEach((values, key) => {
            errors.finances[key] = {}
            if (!values.financingProvider) {
                errors.finances[key].financingProvider = 'Wybierz dostawcę'
            }
            if (!values.financingType) {
                errors.finances[key].financingType = 'Wybierz rodzaj'
            }
            if (!values.net) {
                errors.finances[key].net = 'Uzupełnij cenę'
            }
            if (!values.paymentPercentage) {
                errors.finances[key].paymentPercentage = 'Uzupełnij wpłatę'
            }
            if (!values.payment) {
                errors.finances[key].payment = 'Uzupełnij wpłatę'
            }
            if (!values.period) {
                errors.finances[key].period = 'Uzupełnij okres'
            }
            if (values.repurchasePercentage == null || values.repurchasePercentage < 0) {
                errors.finances[key].repurchasePercentage = 'Uzupełnij wykup'
            }
            if (values.repurchase == null || values.repurchase < 0) {
                errors.finances[key].repurchase = 'Uzupełnij wykup'
            }
            if (values.provision == null) {
                errors.finances[key].provision = 'Uzupełnij prowizję'
            }
            if (values.feesSumPercentage < 0) {
                errors.finances[key].feesSumPercentage = 'Suma opłat musi być dodatnia';
            }
            if (values.feesSum < 0) {
                errors.finances[key].feesSum = 'Suma opłat musi być dodatnia';
            }
        });

        return errors;
    }

    const calculateEur = (newFinances) => {
        const todoFinances = [];

        newFinances.forEach(newFinance => {
            const todoFinance = newFinance;

            if (newFinance.rate > 0) {
                todoFinance.net_eur = (todoFinance.net / newFinance.rate).toFixed(2);
                todoFinance.payment_eur = (todoFinance.payment / newFinance.rate).toFixed(2);
                todoFinance.repurchase_eur = (todoFinance.repurchase / newFinance.rate).toFixed(2);
                todoFinance.netInstallment_eur = (todoFinance.netInstallment / newFinance.rate).toFixed(2);
            }

            todoFinances.push(todoFinance);
        });

        return todoFinances;
    }

    useEffect(() => {
        if (toDoId !== "dodaj") {
            axios.get(`${apiUrl}/toDos/${toDoId}/finance`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(({
                         data: {
                             finances: newFinances,
                             isActive,
                             clientFullName,
                             statuses: newStatuses,
                             finance_status: newStatus,
                             defaultNet: newDefaultNet,
                             isDuplicated,
                             slack
                         }
                     }) => {
                setIsEnabled(isActive);
                setClientName(clientFullName);
                setSlack(slack);
                setIsRenew(isDuplicated);
                if (isActive) {
                    setFinances(calculateEur(newFinances));
                } else {
                    setFinances([
                        {
                            isActive: false,
                            net: newDefaultNet
                        }
                    ]);
                }
                setStatus(newStatus);
                setStatuses(newStatuses)
                setDefaultNet(newDefaultNet);

            }).catch(() => {
                toast.error("Popraw dane");
            });
        }
    }, [token, toDoId]);

    const setFilesToForm = (files, index) => {
        const obj = {
            index,
            files
        }
        if (globalFiles.some(e => e.index === index)) {
            let newArr = [...globalFiles].map((item) => {
                if (item.index === index) {
                    return obj;
                }
                return item;
            });
            setGlobalFiles(newArr);
        } else {
            setGlobalFiles(prevState => [...prevState, obj]);
        }
    }

    const calcPayment = (value, name, index, form, values) => {
        const paymentPercentage = parseFloat(values.finances[index].paymentPercentage);

        if (paymentPercentage) {
            const netValue = parseFloat(value);
            const paymentValue = netValue * paymentPercentage / 100;
            form.mutators.setValue(`${name}.payment`, paymentValue.toFixed(2));

            return paymentValue.toFixed(2);
        }

        return 0;
    }

    const calcFromPercentsPayment = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);

        if (netValue) {
            const paymentValue = netValue * parseFloat(value) / 100;
            form.mutators.setValue(`${name}.payment`, paymentValue.toFixed(2));

            return paymentValue.toFixed(2);
        }

        return 0;
    }

    const changePayment = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);

        if (netValue) {
            const paymentPercentage = parseFloat(value) / netValue * 100;
            form.mutators.setValue(`${name}.paymentPercentage`, paymentPercentage.toFixed(2))
        }

        return value;
    }

    const calcRepurchase = (value, name, index, form, values) => {
        const paymentPercentage = parseFloat(values.finances[index].repurchasePercentage);

        if (paymentPercentage) {
            const repurchaseValue = parseFloat(value) * paymentPercentage / 100;
            form.mutators.setValue(`${name}.repurchase`, repurchaseValue.toFixed(2))

            return repurchaseValue.toFixed(2);
        }

        return 0;
    }

    const calcRepurchaseFromPercents = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);

        if (netValue) {
            if (value) {
                const paymentValue = (netValue * parseFloat(value)) / 100;
                form.mutators.setValue(`${name}.repurchase`, paymentValue.toFixed(2));

                return paymentValue.toFixed(2);
            } else {
                form.mutators.setValue(`${name}.repurchase`, 0);

                return 0;
            }
        }

        return 0;
    }

    const changeRepurchase = (value, name, index, form, values) => {
        const netValue = parseFloat(values.finances[index].net);

        if (netValue) {
            const repurchasePercentage = parseFloat(value) / netValue * 100;
            form.mutators.setValue(`${name}.repurchasePercentage`, repurchasePercentage.toFixed(2));
        }

        return value;
    }

    const changeFeesSum = (name, index, form, values, fieldName, fieldValue) => {
        const paymentValue = fieldName === 'payment' ? parseFloat(fieldValue) : parseFloat(values.finances[index].payment);
        const periodValue = fieldName === 'period' ? parseFloat(fieldValue) : parseFloat(values.finances[index].period);
        const periodPrice = fieldName === 'netInstallment' ? parseFloat(fieldValue) : parseFloat(values.finances[index].netInstallment);
        const repurchase = fieldName === 'repurchase' ? parseFloat(fieldValue) : parseFloat(values.finances[index].repurchase);
        const netValue = fieldName === 'net' ? parseFloat(fieldValue) : parseFloat(values.finances[index].net);

        if (paymentValue && periodValue && periodPrice && netValue) {
            const calc = paymentValue + periodValue * periodPrice + repurchase;
            form.mutators.setValue(`${name}.feesSum`, (calc - netValue).toFixed(2))
            form.mutators.setValue(`${name}.feesSumPercentage`, (calc / netValue * 100).toFixed(2))
        }
    }

    const changeNetFeesSum = (name, index, form, values, payment, rep, net) => {
        const periodValue = parseFloat(values.finances[index].period);
        const periodPrice = parseFloat(values.finances[index].netInstallment);
        const paymentValue = parseFloat(payment);
        const repurchase = parseFloat(rep);
        const netValue = parseFloat(net);

        if (paymentValue && periodValue && periodPrice && netValue) {
            const calc = paymentValue + periodValue * periodPrice + repurchase;
            form.mutators.setValue(`${name}.feesSum`, (calc - netValue).toFixed(2))
            form.mutators.setValue(`${name}.feesSumPercentage`, (calc / netValue * 100).toFixed(2))
        }
    }

    return (
        <>
            <Switch checked={isEnabled} onChange={() => (setIsEnabled(!isEnabled))}/>
            <TopFormWrapper>
                <div>
                    <h2>Finansowanie</h2>
                </div>
                <div>
                    {clientName && (
                        clientName.map((item, key) => (
                            <h2 key={key}>Dla {item}</h2>
                        ))
                    )}

                    <div className="links">
                        <h2>Slack:</h2>
                        {slack && slack['vehicleSlackLink'] && (
                            <a href={slack['vehicleSlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Samochód
                            </a>
                        )}

                        {slack && slack['policySlackLink'] && (
                            <a href={slack['policySlackLink']} target="_blank">
                                <FontAwesomeIcon icon={faArrowRight} color="black" fixedWidth />
                                Polisa
                            </a>
                        )}
                    </div>
                </div>
            </TopFormWrapper>
            {isEnabled && <>
                <Status
                    todoId={toDoId}
                    initialValues={{finance_status: status}}
                    nameField="finance_status"
                    statuses={statuses}
                    disabled={archived || isRenew}
                />
                <Form
                    mutators={{
                        setValue: ([field, value], state, {changeValue}) => {
                            changeValue(state, field, () => value)
                        },
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    validate={values => validate(values)}
                    onSubmit={values => {
                        const fd = new FormData();
                        createFormData(fd, 'finances', values.finances);
                        if (globalFiles.length > 0) {
                            globalFiles.forEach(({index, files}) => {
                                files.forEach((file) => {
                                    fd.append(`finances[${index}][files][${file.index}][file]`, file.file);
                                })
                            });
                        }
                        axios.post(`${apiUrl}/toDos/${toDoId}/finance`, fd, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        }).then(({data: {finances: newFinances, isActive}}) => {
                            setIsEnabled(isActive);
                            if (isActive) {
                                setFinances(calculateEur(newFinances));
                            }
                            toast.success("Dane zostały zapisane.");
                        })
                            .catch(() => {
                                toast.error("Popraw dane");
                            });
                    }}
                    initialValues={{finances}}
                    render={({handleSubmit, form, values}) => (
                        <StyledForm onSubmit={handleSubmit}>

                            <FieldArray name="finances">
                                {({fields}) => (
                                    <>
                                        <ButtonsWrapper>
                                            <RoundSimpleButton
                                                disabled={isRenew}
                                                type="button"
                                                onClick={() => fields.push({isActive: false, net: defaultNet})}>
                                                <FontAwesomeIcon icon={faPlus} color="white"/>
                                            </RoundSimpleButton>
                                            <RoundSimpleButton
                                                disabled={isRenew}
                                                type="button"
                                                onClick={() => fields.pop()}>
                                                <FontAwesomeIcon icon={faMinus} color="white"/>
                                            </RoundSimpleButton>
                                        </ButtonsWrapper>
                                        {fields.map((name, index) => index === 0 ? (
                                            <div key={index}>
                                                <FieldSet disabled={isRenew}>
                                                    <Field
                                                        name={`${name}.id`}
                                                        render={({input, meta}) => (
                                                            <Input {...input} id={input.name} hidden/>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.variantNumber`}
                                                        type="hidden"
                                                        initialValue={index + 1}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.isActive`}
                                                        type="checkbox"
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <VariantLabel>
                                                                        Wariant {index + 1}
                                                                        <Input {...input} id={input.name}
                                                                               placeholder=""/>
                                                                    </VariantLabel>
                                                                </InputWrapperWithoutLabel>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.financingProvider`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Dostawca
                                                                        finansowania*</Label>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Dostawca finansowania</option>
                                                                        <option value="Nex1">Nex1</option>
                                                                        <option value="Obce">Obce</option>
                                                                    </Select>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.institution`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Instytucja*</Label>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Instytucja</option>
                                                                        <option value="ALIOR LEASING SP. Z O.O.">ALIOR
                                                                            LEASING SP. Z O.O.
                                                                        </option>
                                                                        <option
                                                                            value="ATHLON CAR LEASE POLSA SP. Z O.O.">ATHLON
                                                                            CAR LEASE POLSA SP. Z O.O.
                                                                        </option>
                                                                        <option value="SANTANDER LEASING S.A.">SANTANDER
                                                                            LEASING S.A.
                                                                        </option>
                                                                        <option value="MLEASING SP. Z O.O.">MLEASING SP.
                                                                            Z
                                                                            O.O.
                                                                        </option>
                                                                        <option
                                                                            value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.">EUROPEJSKI
                                                                            FUNDUSZ LEASINGOWY S.A. / EFL S.A.
                                                                        </option>
                                                                        <option
                                                                            value="VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE">VOLKSWAGEN
                                                                            LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE
                                                                        </option>
                                                                        <option
                                                                            value="RCI LEASING POLSKA SP. Z O.O.">RCI
                                                                            LEASING POLSKA SP. Z O.O.
                                                                        </option>
                                                                        <option value="PKO LEASING S.A.">PKO LEASING
                                                                            S.A.
                                                                        </option>
                                                                        <option
                                                                            value="FCA LEASING POLSKA SP. Z O.O.">FCA
                                                                            LEASING POLSKA SP. Z O.O.
                                                                        </option>
                                                                        <option value="IDEA FINANCE SP. Z O.O.">IDEA
                                                                            FINANCE
                                                                            SP. Z O.O.
                                                                        </option>
                                                                        <option value="IDEA FLEET S.A.">IDEA FLEET S.A.
                                                                        </option>
                                                                        <option value="GETIN LEASING S.A.">GETIN LEASING
                                                                            S.A.
                                                                        </option>
                                                                        <option value="GETIN FLEET S.A.">GETIN FLEET
                                                                            S.A.
                                                                        </option>
                                                                        <option value="ALIOR LEASING">Alior Leasing
                                                                        </option>
                                                                        <option value="ATHLON CAR LEASE POLSKA">Athlon
                                                                            Car
                                                                            Lease Polska
                                                                        </option>
                                                                        <option value="BOŚ LEASING ">BOŚ Leasing
                                                                        </option>
                                                                        <option
                                                                            value="BMW FINANCIAL SERVICES POLSKA SP. Z O.O.">BMW
                                                                            Financial Services Polska Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="BNP PARIBAS LEASE GROUP SP. Z O.O.">BNP
                                                                            Paribas Lease Group Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="BPS LEASING SPÓŁKA AKCYJNA (BPS LEASING S.A.)">BPS
                                                                            Leasing Spółka Akcyjna (BPS Leasing S.A.)
                                                                        </option>
                                                                        <option
                                                                            value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A.">EUROPEJSKI
                                                                            FUNDUSZ LEASINGOWY S.A.
                                                                        </option>
                                                                        <option value="IDEA GETIN LEASING S.A.">Idea
                                                                            Getin
                                                                            Leasing S.A.
                                                                        </option>
                                                                        <option
                                                                            value="IMPULS-LEASING POLSKA SP. Z O.O.">IMPULS-LEASING
                                                                            Polska Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="ING LEASE (POLSKA) SP. Z O.O.">ING
                                                                            LEASE (Polska) Sp. z o.o.
                                                                        </option>
                                                                        <option value="ING Finance">ING Finance</option>
                                                                        <option
                                                                            value="MILLENNIUM LEASING SP. Z O.O.">Millennium
                                                                            Leasing Sp. z o.o.
                                                                        </option>
                                                                        <option value="MLEASING SP. Z O.O.">mLeasing Sp.
                                                                            z
                                                                            o.o.
                                                                        </option>
                                                                        <option value="PEKAO LEASING SP. Z O.O.">PEKAO
                                                                            LEASING Sp. z o.o.
                                                                        </option>
                                                                        <option value="PKO LEASING S.A.">PKO LEASING
                                                                            S.A.
                                                                        </option>
                                                                        <option
                                                                            value="PSA FINANCE POLSKA SP. Z O.O.">PSA
                                                                            Finance Polska sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="SANTANDER CONSUMER MULTIRENT SP.Z O.O.">Santander
                                                                            Consumer Multirent Sp.z o.o.
                                                                        </option>
                                                                        <option value="SANTANDER LEASING S.A.">Santander
                                                                            Leasing S.A.
                                                                        </option>
                                                                        <option value="SGB LEASING SP. Z O.O.">SGB
                                                                            Leasing
                                                                            Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="SIEMENS FINANCE SP. Z O.O.">SIEMENS
                                                                            FINANCE Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="TOYOTA LEASING POLSKA SP. Z O.O.">TOYOTA
                                                                            LEASING POLSKA SP. Z O.O.
                                                                        </option>
                                                                        <option value="ING Finance">VEHIS</option>
                                                                        <option value="Wynajem FINZEN">
                                                                            Wynajem FINZEN
                                                                        </option>
                                                                        <option value="Wynajem NEX1">
                                                                            Wynajem NEX1
                                                                        </option>
                                                                    </Select>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.financingType`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Rodzaj
                                                                        finansowania*</Label>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Rodzaj finansowania</option>
                                                                        <option value="Leasing operacyjny">Leasing
                                                                            operacyjny
                                                                        </option>
                                                                        <option value="Pożyczka">Pożyczka</option>
                                                                        <option value="CFM">CFM</option>
                                                                        <option value="Leasing finansowy">Leasing
                                                                            finansowy
                                                                        </option>
                                                                        <option value="Kredyt">Kredyt</option>
                                                                        <option value="Kredyt">STOK</option>
                                                                    </Select>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.rate`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Kurs EUR</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               const rate = parseFloat(e.target.value);

                                                                               form.mutators.setValue(`${name}.net_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].net) / rate).toFixed(2) : null);

                                                                               form.mutators.setValue(`${name}.payment_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].payment) / rate).toFixed(2) : null);

                                                                               form.mutators.setValue(`${name}.repurchase_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].repurchase) / rate).toFixed(2) : null);

                                                                               form.mutators.setValue(`${name}.netInstallment_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].netInstallment) / rate).toFixed(2) : null);
                                                                           }}
                                                                           step="0.01"/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.net`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Cena netto*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const payment = calcPayment(e.target.value, name, index, form, values);
                                                                               const repurchase = calcRepurchase(e.target.value, name, index, form, values);
                                                                               changeNetFeesSum(name, index, form, values, payment, repurchase, e.target.value);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.net_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.net_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Cena netto EUR</Label>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.paymentPercentage`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        validate={maxValue(100)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Wpłata%*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const rep = calcFromPercentsPayment(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'repurchase', rep);
                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.payment`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Wpłata netto
                                                                        PLN*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const payment = changePayment(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'payment', payment);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.payment_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.payment_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Wpłata netto EUR</Label>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.period`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Okres mc*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               changeFeesSum(name, index, form, values, 'period', e.target.value);
                                                                           }}
                                                                           step="1"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.repurchasePercentage`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        validate={maxValue(100)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Wykup%*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const rep = calcRepurchaseFromPercents(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'repurchase', rep);
                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.repurchase`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Wykup netto*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const rep = changeRepurchase(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'repurchase', rep);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.repurchase_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.repurchase_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Wykup netto EUR</Label>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.provision`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Prowizja*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.margin`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>

                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Marża</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.netInstallment`}
                                                        type={"number"}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Rata netto</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               changeFeesSum(name, index, form, values, 'netInstallment', e.target.value);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.netInstallment_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.netInstallment_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Rata netto EUR</Label>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapper>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.additionalProduct`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Produkt
                                                                        dodatkowy</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <p>OPŁATY DODATKOWE</p>
                                                    <Field
                                                        name={`${name}.registrationFee`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Opłata za
                                                                        rejestrację*</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.administrationFee`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Opłata
                                                                        administracyjna</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapper>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.foreignInsuranceFee`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Ubezpieczenie
                                                                        obce</Label>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapper>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.feesSumPercentage`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Suma opłat %</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"/>
                                                                </InputWrapper>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <StyledDivError>{meta.error || meta.submitError}</StyledDivError>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.feesSum`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapper>
                                                                    <Label htmlFor={input.name}>Suma opłat PLN</Label>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"/>
                                                                </InputWrapper>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <StyledDivError>{meta.error || meta.submitError}</StyledDivError>
                                                                )}
                                                            </>
                                                        )}/>

                                                    <Files name={`${name}.files`} index={index}
                                                           setFilesToForm={setFilesToForm} disabled={isRenew}/>

                                                    {(!archived && !isRenew) && (
                                                        <StyledButton>Zapisz</StyledButton>
                                                    )}
                                                    <StyledButton inactive type="button" onClick={() => {
                                                        downloadFile(
                                                            `${apiUrl}/toDos/${toDoId}/finance/print`,
                                                            token,
                                                            `${toDoId}-finansowanie.pdf`
                                                        );
                                                    }}>Drukuj</StyledButton>
                                                </FieldSet>
                                            </div>
                                        ) : (
                                            <div key={index}>
                                                <FieldSet disabled={isRenew}>
                                                    <Field
                                                        name={`${name}.id`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <Input {...input} id={input.name} hidden/>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.variantNumber`}
                                                        type="hidden"
                                                        initialValue={index + 1}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <Input {...input} id={input.name} placeholder=""/>
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${name}.isActive`}
                                                        type="checkbox"
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <VariantLabel>
                                                                        Wariant {index + 1}
                                                                        <Input {...input} id={input.name}
                                                                               placeholder=""/>
                                                                    </VariantLabel>
                                                                </InputWrapperWithoutLabel>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.financingProvider`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Dostawca finansowania</option>
                                                                        <option value="Nex1">Nex1</option>
                                                                        <option value="Obce">Obce</option>
                                                                    </Select>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.institution`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Instytucja</option>
                                                                        <option value="ALIOR LEASING SP. Z O.O.">ALIOR
                                                                            LEASING SP. Z O.O.
                                                                        </option>
                                                                        <option
                                                                            value="ATHLON CAR LEASE POLSA SP. Z O.O.">ATHLON
                                                                            CAR LEASE POLSA SP. Z O.O.
                                                                        </option>
                                                                        <option value="SANTANDER LEASING S.A.">SANTANDER
                                                                            LEASING S.A.
                                                                        </option>
                                                                        <option value="MLEASING SP. Z O.O.">MLEASING SP.
                                                                            Z
                                                                            O.O.
                                                                        </option>
                                                                        <option
                                                                            value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A. / EFL S.A.">EUROPEJSKI
                                                                            FUNDUSZ LEASINGOWY S.A. / EFL S.A.
                                                                        </option>
                                                                        <option
                                                                            value="VOLKSWAGEN LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE">VOLKSWAGEN
                                                                            LEASING GMBH SP. Z O.O. ODDZIAŁ W POLSCE
                                                                        </option>
                                                                        <option
                                                                            value="RCI LEASING POLSKA SP. Z O.O.">RCI
                                                                            LEASING POLSKA SP. Z O.O.
                                                                        </option>
                                                                        <option value="PKO LEASING S.A.">PKO LEASING
                                                                            S.A.
                                                                        </option>
                                                                        <option
                                                                            value="FCA LEASING POLSKA SP. Z O.O.">FCA
                                                                            LEASING POLSKA SP. Z O.O.
                                                                        </option>
                                                                        <option value="IDEA FINANCE SP. Z O.O.">IDEA
                                                                            FINANCE
                                                                            SP. Z O.O.
                                                                        </option>
                                                                        <option value="IDEA FLEET S.A.">IDEA FLEET S.A.
                                                                        </option>
                                                                        <option value="GETIN LEASING S.A.">GETIN LEASING
                                                                            S.A.
                                                                        </option>
                                                                        <option value="GETIN FLEET S.A.">GETIN FLEET
                                                                            S.A.
                                                                        </option>
                                                                        <option value="ALIOR LEASING">Alior Leasing
                                                                        </option>
                                                                        <option value="ATHLON CAR LEASE POLSKA">Athlon
                                                                            Car
                                                                            Lease Polska
                                                                        </option>
                                                                        <option value="BOŚ LEASING ">BOŚ Leasing
                                                                        </option>
                                                                        <option
                                                                            value="BMW FINANCIAL SERVICES POLSKA SP. Z O.O.">BMW
                                                                            Financial Services Polska Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="BNP PARIBAS LEASE GROUP SP. Z O.O.">BNP
                                                                            Paribas Lease Group Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="BPS LEASING SPÓŁKA AKCYJNA (BPS LEASING S.A.)">BPS
                                                                            Leasing Spółka Akcyjna (BPS Leasing S.A.)
                                                                        </option>
                                                                        <option
                                                                            value="EUROPEJSKI FUNDUSZ LEASINGOWY S.A.">EUROPEJSKI
                                                                            FUNDUSZ LEASINGOWY S.A.
                                                                        </option>
                                                                        <option value="IDEA GETIN LEASING S.A.">Idea
                                                                            Getin
                                                                            Leasing S.A.
                                                                        </option>
                                                                        <option
                                                                            value="IMPULS-LEASING POLSKA SP. Z O.O.">IMPULS-LEASING
                                                                            Polska Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="ING LEASE (POLSKA) SP. Z O.O.">ING
                                                                            LEASE (Polska) Sp. z o.o.
                                                                        </option>
                                                                        <option value="ING Finance">ING Finance</option>
                                                                        <option
                                                                            value="MILLENNIUM LEASING SP. Z O.O.">Millennium
                                                                            Leasing Sp. z o.o.
                                                                        </option>
                                                                        <option value="MLEASING SP. Z O.O.">mLeasing Sp.
                                                                            z
                                                                            o.o.
                                                                        </option>
                                                                        <option value="PEKAO LEASING SP. Z O.O.">PEKAO
                                                                            LEASING Sp. z o.o.
                                                                        </option>
                                                                        <option value="PKO LEASING S.A.">PKO LEASING
                                                                            S.A.
                                                                        </option>
                                                                        <option
                                                                            value="PSA FINANCE POLSKA SP. Z O.O.">PSA
                                                                            Finance Polska sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="SANTANDER CONSUMER MULTIRENT SP.Z O.O.">Santander
                                                                            Consumer Multirent Sp.z o.o.
                                                                        </option>
                                                                        <option value="SANTANDER LEASING S.A.">Santander
                                                                            Leasing S.A.
                                                                        </option>
                                                                        <option value="SGB LEASING SP. Z O.O.">SGB
                                                                            Leasing
                                                                            Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="SIEMENS FINANCE SP. Z O.O.">SIEMENS
                                                                            FINANCE Sp. z o.o.
                                                                        </option>
                                                                        <option
                                                                            value="TOYOTA LEASING POLSKA SP. Z O.O.">TOYOTA
                                                                            LEASING POLSKA SP. Z O.O.
                                                                        </option>
                                                                        <option value="ING Finance">VEHIS</option>
                                                                        <option value="Wynajem FINZEN">
                                                                            Wynajem FINZEN
                                                                        </option>
                                                                        <option value="Wynajem NEX1">
                                                                            Wynajem NEX1
                                                                        </option>
                                                                    </Select>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.financingType`}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Select {...input} id={input.name}>
                                                                        <option value="">Rodzaj finansowania</option>
                                                                        <option value="Leasing operacyjny">Leasing
                                                                            operacyjny
                                                                        </option>
                                                                        <option value="Pożyczka">Pożyczka</option>
                                                                        <option value="CFM">CFM</option>
                                                                        <option value="Leasing finansowy">Leasing
                                                                            finansowy
                                                                        </option>
                                                                        <option value="Kredyt">Kredyt</option>
                                                                        <option value="Kredyt">STOK</option>
                                                                        <option value="brak">brak</option>
                                                                    </Select>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.rate`}
                                                        type="number"
                                                        min="0"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           onChange={(e) => {
                                                                               input.onChange(e);
                                                                               const rate = parseFloat(e.target.value);

                                                                               form.mutators.setValue(`${name}.net_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].net) / rate).toFixed(2) : null);

                                                                               form.mutators.setValue(`${name}.payment_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].payment) / rate).toFixed(2) : null);

                                                                               form.mutators.setValue(`${name}.repurchase_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].repurchase) / rate).toFixed(2) : null);

                                                                               form.mutators.setValue(`${name}.netInstallment_eur`,
                                                                                   rate > 0 ? (parseFloat(values.finances[index].netInstallment) / rate).toFixed(2) : null);
                                                                           }}
                                                                           step="0.01"/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.net`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const payment = calcPayment(e.target.value, name, index, form, values);
                                                                               const repurchase = calcRepurchase(e.target.value, name, index, form, values);
                                                                               changeNetFeesSum(name, index, form, values, payment, repurchase, e.target.value);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.net_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.net_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.paymentPercentage`}
                                                        type="number"
                                                        validate={maxValue(100)}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const rep = calcFromPercentsPayment(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'repurchase', rep);
                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.payment`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const payment = changePayment(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'payment', payment);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.payment_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.payment_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.period`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               changeFeesSum(name, index, form, values, 'period', e.target.value);
                                                                           }}
                                                                           step="1"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.repurchasePercentage`}
                                                        type="number"
                                                        validate={maxValue(100)}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const rep = calcRepurchaseFromPercents(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'repurchase', rep);
                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.repurchase`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               const rep = changeRepurchase(e.target.value, name, index, form, values);
                                                                               changeFeesSum(name, index, form, values, 'repurchase', rep);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.repurchase_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="0.01"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.repurchase_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.provision`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.margin`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.netInstallment`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"
                                                                           onKeyUp={(e) => {
                                                                               input.onChange(e);
                                                                               changeFeesSum(name, index, form, values, 'netInstallment', e.target.value);
                                                                           }}
                                                                           onChange={(e) => {
                                                                               input.onChange(e);

                                                                               const eurValue = parseFloat(values.finances[index].rate) > 0 ?
                                                                                   parseFloat(e.target.value) / parseFloat(values.finances[index].rate) : 0;

                                                                               form.mutators.setValue(`${name}.netInstallment_eur`, eurValue.toFixed(2));

                                                                           }}
                                                                           step="1"/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.netInstallment_eur`}
                                                        type="number"
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder="" disabled/>
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>

                                                    <Field
                                                        name={`${name}.additionalProduct`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <p>&nbsp;</p>
                                                    <Field
                                                        name={`${name}.registrationFee`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                    {meta.error && meta.touched &&
                                                                        <StyledDivError>{meta.error}</StyledDivError>}
                                                                </InputWrapperWithoutLabel>
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.administrationFee`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.foreignInsuranceFee`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""/>
                                                                </InputWrapperWithoutLabel>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <span>{meta.error || meta.submitError}</span>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.feesSumPercentage`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"/>
                                                                </InputWrapperWithoutLabel>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <StyledDivError>{meta.error || meta.submitError}</StyledDivError>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Field
                                                        name={`${name}.feesSum`}
                                                        parse={value => (value === "" ? null : value)}
                                                        render={({input, meta}) => (
                                                            <>
                                                                <InputWrapperWithoutLabel>
                                                                    <Input {...input} id={input.name} placeholder=""
                                                                           min="0"/>
                                                                </InputWrapperWithoutLabel>
                                                                {(meta.error || meta.submitError) && meta.touched && (
                                                                    <StyledDivError>{meta.error || meta.submitError}</StyledDivError>
                                                                )}
                                                            </>
                                                        )}/>
                                                    <Files name={`${name}.files`} index={index}
                                                           setFilesToForm={setFilesToForm} disabled={isRenew}/>
                                                </FieldSet>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </FieldArray>
                        </StyledForm>
                    )}
                />
            </>}
            <Comment toDoId={toDoId} toDoTab={"finance"} vehicleId={null} policyId={null} archived={false} history={false} />
        </>
    );
};
